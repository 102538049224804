<template>
  <div class="check-detail">
    <global-header title="单品验货"/>
    <gong-huo-tab-page>
      <GongHuoCheckInfoTab title="基础信息" name="basic" :detail="orderItemDetail"/>
      <GongHuoCheckItemsTab title="检查项" name="items" :id="singleProductId" :order-item-id="orderItemId" :order-item-detail="orderItemDetail"/>
    </gong-huo-tab-page>
  </div>
</template>

<script>
import GlobalHeader from '@/components/Common/GlobalHeader.vue'
import GongHuoTabPage from '@/components/Common/GongHuoTabPage.vue'
import GongHuoCheckInfoTab from '@/components/GongHuoCheckDetail/GongHuoCheckInfoTab.vue'
import GongHuoCheckItemsTab from '@/components/GongHuoCheckDetail/GongHuoCheckItemsTab.vue'
import {getAction} from '@/api/manage';
import inobounce from 'inobounce';

export default {
  name: 'GonghuoCheckDetail',
  components: {GongHuoCheckItemsTab, GongHuoCheckInfoTab, GongHuoTabPage, GlobalHeader},
  data() {
    return {
      orderItemId: this.$route.query.orderItemId,
      singleProductId: this.$route.query.singleProductId,
      orderItemDetail: {}
    }
  },
  created() {
    this.getOrderItemDetail()
  },
  mounted() {
    let u = navigator.userAgent
    if (u.indexOf('iPhone') > -1) {
      inobounce.enable()
    }
  },
  beforeDestroy() {
    inobounce.disable()
  },
  methods: {
    async getOrderItemDetail() {
      const res = await getAction('/jeecg-system/task/taskOrderItem/queryById', {
        id: this.orderItemId
      })
      if (res.success) {
        console.log(this.orderItemId);
        this.orderItemDetail = res.result
        this.orderItemDetail.title = `${res.result.orderNo}-${res.result.singleProductNo}-${res.result.singleProductName}`
      } else {
        this.Toast.fail(res.message)
      }
    }
  }
}
</script>

<style scoped>

</style>
