<template>
  <van-tab :title="title" v-bind="$attrs">
    <div class="tab-content">
      <!-- :style="detail.supplierComments == 'PENDING'?'height: calc(100vh - 106px - 66px)':'height: calc(100vh - 66px)'" -->
      <!-- <card :title="orderItemDetail.title">
        <span class="basic-item">申请数量: {{orderItemDetail.inspectQuantity}}</span>
        <span class="basic-item">抽检水平: {{ SAMPLING_LEVEL_DICT[orderItemDetail.samplingLevel] }}</span>
        <span class="basic-item">建议抽检: {{orderItemDetail. sampleQuantity}}</span>
      </card> -->
      <van-collapse v-model="active" class="collapse-box">
        <van-collapse-item
          v-for="(item1, index1) in checkItemList"
          :key="index1"
          :name="index1"
        >
          <!-- 已完成单品 -->
          <div
            class="check-item check-item-1 vertical-center content_box"
            slot="title"
            @click="openPopup(item1)"
          >
            <!-- {{ item1.taskInspection.checkpointJudge }} -->
            <div
              style="display: flex; align-items: center"
              v-if="item1.taskInspection"
            >
              <!-- 四个状态对应的圆点    
                QUALIFIED("合格"),
                FAILED("不合格"),
                PENDING("待定"),
                UNCHECKED("未检"); -->
              <!-- <div v-if="item1.taskInspection"> -->
              <i
                v-if="item1.taskInspection.checkpointJudge == 'QUALIFIED'"
                class="yuandian"
                style="background-color: #00bf46"
              ></i>
              <i
                v-if="item1.taskInspection.checkpointJudge == 'PENDING'"
                class="yuandian"
                style="background-color: #ffad16"
              ></i>
              <i
                v-if="item1.taskInspection.checkpointJudge == 'FAILED'"
                class="yuandian"
                style="background-color: #f94837"
              ></i>
              <i
                v-if="item1.taskInspection.checkpointJudge == 'UNCHECKED'"
                class="yuandian"
                style="background-color: #055c9d"
              ></i>
              <!-- </div> -->
            </div>
            {{ item1.checkName }}
            <div
              style="
                display: flex;
                align-items: center;
                flex: 1;
                justify-content: end;
              "
            >
              <div v-if="item1.taskInspection">
                <div
                  v-if="item1.taskInspection.checkpointJudge == 'QUALIFIED'"
                  class="isQualified"
                  style="color: #00bf46"
                >
                  合格
                </div>
                <!-- UNCHECKED("未检"); -->
                <!-- taskInspectionExpands -->
                <div
                  v-if="item1.taskInspection.checkpointJudge == 'UNCHECKED'"
                  class="isQualified"
                  style="color: #055c9d"
                >
                  未检
                </div>
                <div
                  v-if="item1.taskInspection.checkpointJudge == 'PENDING'"
                  class="isQualified"
                  style="color: #ffad16"
                >
                  待定
                </div>
                <div
                  v-if="item1.taskInspection.checkpointJudge == 'FAILED'"
                  class="isQualified"
                  style="color: #f94837"
                >
                  不合格
                </div>
              </div>
              <img src="~@/assets/svg/icon-caret.svg" class="caret-img" />
            </div>
          </div>
          <!-- 进行中单品 -->
          <!-- <div v-else slot="title" @click="jxzOpenPopup(item1)">
            {{ item1.checkName }}
            <span>{{item1.taskInspection}}</span>
          </div> -->

          <!-- <van-collapse
            v-if="item1.children && item1.children.length"
            v-model="item1.active"
          >
            <div>内容</div>

            <van-collapse-item v-for="(item2, index2) in  item1.children" :key="index2" :name="index2">
              <div class="check-item check-item-2 vertical-center" slot="title">
                <img :class="{'is-active':  item1.active && item1.active.includes(index2)}"
                     src="~@/assets/svg/icon-caret.svg" alt="" class="caret-img">
                <div>{{ item2.itemName }}</div>
                <img v-if="item2.checkpointType === CHECKPOINT_CUSTOM" src="~@/assets/svg/icon-delete.svg" alt="" class="del-img"
                     @click.stop="handleItemDelClick(item2)">
              </div>
              <van-collapse v-if="item2.children && item2.children.length && !isHiddenThree(item2)" v-model="item2.active">
                <van-collapse-item v-for="(item3, index3) in  item2.children" :key="index3" :name="index3">
                  <div class="check-item check-item-3 vertical-center" slot="title">
                    <img :class="{'is-active':  item2.active && item2.active.includes(index3)}"
                         src="~@/assets/svg/icon-caret.svg"
                         alt="" class="caret-img">
                    <div>{{ item3.itemName }}</div>
                  </div>
                  <div class="check-detail check-detail-3">{{ item3.children[0].checkDescription }}</div>
                </van-collapse-item>
              </van-collapse>
              <div
                  v-else-if="isHiddenThree(item2)"
                  class="check-detail check-detail-2">
                {{item2.children[0].children[0].checkDescription}}
              </div>
              <div v-else class="check-detail check-detail-2">{{ item2.checkDescription }}</div>
            </van-collapse-item>
          </van-collapse> -->
        </van-collapse-item>
      </van-collapse>
      <!-- 条形码弹窗 -->
      <custom-popup
        :show="showPopup"
        @on-close="showPopup = false"
        :title="showTitle"
        position="top"
      >
        <div class="check_box">
          <div class="check_title">条形码数字</div>
          <div class="check_content">
            {{ checkList.barcodeValue }}
          </div>
        </div>
        <div class="check_box">
          <div class="check_title">条形码格式</div>
          <div class="check_content">
            {{ checkList.barcodeFormat }}
          </div>
        </div>
        <div class="check_box">
          <div class="check_title">检查要求</div>
          <div class="check_content">
            <!-- {{ checkList.checkPointName }} -->
            {{ checkList.checkDescription }}
          </div>
        </div>
        <div class="check_box">
          <div class="check_title">扫描记录</div>
          <div class="check_content" v-if="checkList.taskInspection">
            <!-- <span  v-for="item in checkList.fileUrl.split(',')" :key="item+'key'"> -->
            <!-- {{item}} -->
            <!-- </span> -->
            <!-- {{checkList.taskInspection.taskInspectionExpands}} -->
            <div
              v-for="(child, index) in checkList.taskInspection
                .taskInspectionExpands"
              :key="index + 'KEY'"
              style="margin-bottom: 5px"
            >
              {{ child.scanTime }} {{ child.createBy }}
            </div>
          </div>
        </div>
        <div class="check_box">
          <div class="check_title">拍照要求</div>
          <div class="check_content" style="flex-direction: column">
            <div>{{ checkList.photoNumber }}</div>
            <div v-if="checkList.taskInspection">
              <div
                v-if="checkList.taskInspection.images"
                style="display: flex; flex-direction: column"
              >
                <img
                  v-for="(imgs, index) in checkList.taskInspection.imgList"
                  :src="imgs"
                  :key="index + 'KEY'"
                  style="width: 70px; height: 70px; margin-bottom: 10px"
                  @click="lookImg(imgs)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="check_box">
          <div class="check_title">检查点备注</div>
          <!-- <div class="check_content">
            {{ checkList.checkDescription }}
          </div> -->
          <div class="check_content" v-if="checkList.taskRemarkList">
            {{ checkList.taskRemarkList.description }}
          </div>
        </div>
        <div class="check_box">
          <div class="check_title">检查点判定</div>
          <div class="check_content" v-if="checkList.taskInspection">
            {{
              checkList.taskInspection.checkpointJudge
                ? jcdpandin(checkList.taskInspection.checkpointJudge)
                : "无"
            }}
          </div>
        </div>
      </custom-popup>
      <!-- 检查项弹窗 -->
      <custom-popup
        :show="jcshowPopup"
        @on-close="jcshowPopup = false"
        :title="showTitle"
        position="top"
      >
        <div class="check_box">
          <div class="check_title">检查要求</div>
          <div class="check_content">
            <!-- {{ jccheckList.checkpointName }} -->
            {{ jccheckList.checkDescription }}
          </div>
        </div>
        <div class="check_box">
          <div class="check_title">检查项辅助文件：</div>
          <div class="check_content">
            <!-- {{ jccheckList.barcodeFormat }} -->
            <div class="loogFile_box">
              <span>{{ jccheckList.fileUrl }}</span>
              <a
                target="_blank"
                :href="jccheckList.fileUrl"
                v-if="jccheckList.fileUrl"
                >查看</a
              >
            </div>
            <!-- <a target="_blank" :href="file.url" class="btn">下载</a> -->
            <!-- <van-uploader v-model="fileList" multiple disabled/> -->
          </div>
        </div>
        <div class="check_box">
          <div class="check_title">拍照要求</div>
          <div class="check_content" style="flex-direction: column">
            <div>{{ jccheckList.photoDescription }}</div>
            <br />
            <div v-if="jccheckList.taskInspection">
              <div
                v-if="jccheckList.taskInspection.images"
                style="display: flex; flex-direction: column"
              >
                <img
                  v-for="(imgs, index) in jccheckList.taskInspection.imgList"
                  :src="imgs"
                  :key="index + 'KEY'"
                  style="width: 70px; height: 70px; margin-bottom: 10px"
                  @click="lookImg(imgs)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="check_box">
          <div class="check_title">检查点备注</div>
          <div class="check_content" v-if="jccheckList.taskRemarkList">
            {{ jccheckList.taskRemarkList.description }}
          </div>
        </div>
        <div class="check_box">
          <div class="check_title">检查点判定</div>
          <div class="check_content" v-if="jccheckList.taskInspection">
            {{
              jccheckList.taskInspection.checkpointJudge
                ? jcdpandin(jccheckList.taskInspection.checkpointJudge)
                : "无"
            }}
          </div>
        </div>
      </custom-popup>
      <!-- <van-cell is-link @click="showPopup">展示弹出层</van-cell> -->
    </div>
  </van-tab>
</template>

<script>
import {
  SAMPLING_LEVEL_DICT,
  CHECK_TYPE_P_BAR,
  CHECK_TYPE_P_ITEM,
  CHECK_TYPE_S_BAR,
  CHECK_TYPE_S_ITEM,
  CHECKPOINT_ATTRIBUTE,
  CHECKPOINT_CUSTOM,
} from "@/constants";
import CustomPopup from "@/components/Common/CustomPopup.vue";
import Card from "@/components/Common/Card.vue";
import NewCheckPointPopup from "@/components/TaskCheck/NewCheckPointPopup.vue";
import { formatTreeData, formatCheckObj } from "@/utils/util";
import { getAction, deleteAction } from "@/api/manage";
import { ImagePreview } from "vant";
import { Uploader } from "vant";
export default {
  showPopup: false,
  jcshowPopup: false,
  name: "CheckItemsTab",
  components: { Card, NewCheckPointPopup, CustomPopup, Uploader },
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    orderItemId: {
      type: String,
      required: true,
    },
    orderItemDetail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      fileList: [
        {
          url: "https://daxiangcd.oss-cn-chengdu.aliyuncs.com/upload/62137  Mata 65_1661496284201.pdf",
        },
        // Uploader 根据文件后缀来判断是否为图片文件
        // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
        // { url: "https://cloud-image", isImage: true },
      ],
      jcshowPopup: false,
      SAMPLING_LEVEL_DICT,
      CHECKPOINT_CUSTOM,
      checkItemList: [],
      active: [],
      showPopup: false,
      btnLoading: false,
      showTitle: "",
      checkList: {},
      jccheckList: {},
    };
  },
  computed: {
    isHiddenThree() {
      return function (item) {
        return (
          item.checkpointType === CHECKPOINT_CUSTOM ||
          [CHECK_TYPE_S_BAR, CHECK_TYPE_P_BAR, CHECK_TYPE_S_ITEM].includes(
            item.checkType
          )
        );
      };
    },
  },
  created() {
    this.getSingleProductCheck();
  },
  methods: {
    ImagePreview,
    lookImg(url) {
      this.ImagePreview([url]);
    },
    handleStartClick() {
      this.$router.push({
        name: `taskCheck`,
        query: {
          ...this.$route.query,
          step: this.orderItemDetail?.status === "COMPLETED" ? "3" : "1",
        },
      });
    },
    handleItemDelClick(item) {
      this.Dialog.confirm({
        title: "",
        message: "确认删除自定义检查点？",
      })
        .then(async () => {
          const res = await deleteAction(
            "/jeecg-system/admin/singleProductCheck/delete",
            {
              id: item.customCheckpointId,
            }
          );
          if (res.success) {
            this.getSingleProductCheck();
          } else this.Toast.fail(res.message);
        })
        .catch(() => {});
    },
    openPopup(data) {
      console.log(data);
      // 条形码弹窗
      if (data.checkType == "SINGLE_BARCODE") {
        let imgList = [];
        this.checkList = data;
        if (data.taskInspection) {
          if (data.taskInspection.images) {
            imgList = data.taskInspection.images.split(",");
            this.checkList.taskInspection.imgList = imgList;
          }
        }
        this.showTitle = data.checkName + "-" + data.checkPointName;
        this.showPopup = !this.showPopup;
      } else {
        // 检查项弹窗
        let imgList = [];
        this.jccheckList = data;

        if (data.taskInspection) {
          if (data.taskInspection.images) {
            imgList = data.taskInspection.images.split(",");
            this.jccheckList.taskInspection.imgList = imgList;
          }
        }
        console.log(this.jccheckList);
        this.showTitle = data.checkName + "-" + data.checkPointName;
        this.jcshowPopup = !this.jcshowPopup;
      }
    },
    // jxzOpenPopup(data) {
    //   console.log(data);
    //   // 条形码弹窗
    //   if (data.checkType == "SINGLE_BARCODE") {
    //     let imgList = [];
    //     this.checkList = data;
    //     if (data.taskInspection) {
    //       if (data.taskInspection.images) {
    //         imgList = data.taskInspection.images.split(",");
    //         this.checkList.taskInspection.imgList = imgList;
    //       }
    //     }
    //     this.showTitle = data.checkName + "-" + data.checkPointName;
    //     this.showPopup = !this.showPopup;
    //   } else {
    //     // 检查项弹窗
    //     let imgList = [];
    //     this.jccheckList = data;
    //     if (data.taskInspection) {
    //       if (data.taskInspection.images) {
    //         imgList = data.taskInspection.images.split(",");
    //         this.jccheckList.taskInspection.imgList = imgList;
    //       }
    //     }
    //     console.log(this.jccheckList);
    //     this.showTitle = data.checkName + "-" + data.checkPointName;
    //     this.jcshowPopup = !this.jcshowPopup;
    //   }
    // },
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    handlePopupOk(form) {
      this.getSingleProductCheck();
      this.showPopup = !this.showPopup;
    },
    async getSingleProductCheck() {
      const res = await getAction(
        "/jeecg-system/admin/singleProductInfo/queryCheckById",
        {
          id: this.id,
          taskOrderItemId: this.orderItemId,
        }
      );
      if (res.success) {
        // this.checkItemList = formatCheckObj(res.result)
        this.checkItemList = res.result;
      } else {
        this.Toast.fail(res.message);
      }
    },
    jcdpandin(val) {
      if (val == "QUALIFIED") return "合格";
      if (val == "FAILED") return "不合格";
      if (val == "PENDING") return "待定";
      if (val == "UNCHECKED") return "未检";
    },
  },
};
</script>

<style scoped lang="less">
// .content_box{
//   border-bottom: 0.02667rem solid #dfe4eb!important;
// }
.check_box {
  margin-top: 12px;
  display: flex;
}
.check_title {
  width: 1.8rem;
  // height: 18px;
  font-family: SourceHanSansSC-Regular;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
}
.check_content {
  flex: 1;
  margin-left: 20px;
  font-family: SourceHanSansSC-Regular;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  letter-spacing: 0;
  line-height: 22px;
  word-break: break-all;
}
.loogFile_box {
  display: flex;
  > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: calc(100vw - 181px);
    display: block;
  }
}
.yuandian {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 4px 8px;
}
.isQualified {
  width: 80px;
  text-align: right;
}
.tab-content {
  // height: calc(100vh - 106px - 134px);

  .basic-item {
    margin-right: 8px;
    color: rgba(#000, 0.4);
    font-size: 14px;
  }

  .collapse-box {
    margin-top: 16px;

    .check-item {
      position: relative;

      .caret-img {
        width: 16px;
        height: 16px;
        margin-left: 8px;

        &.is-active {
          transform: rotate(90deg);
        }
      }

      &.check-item-2 .caret-img {
        margin-left: 32px;
      }

      &.check-item-3 .caret-img {
        margin-left: 60px;
      }

      .del-img {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 16px;
      }
    }

    .check-detail {
      white-space: pre-line;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      text-align: left;
      line-height: 22px;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-right: 8px;

      &.check-detail-1 {
        padding-left: 64px;
      }

      &.check-detail-2 {
        padding-left: 80px;
      }

      &.check-detail-3 {
        padding-left: 96px;
      }
    }
  }

  ::v-deep {
    .van-collapse-item__content {
      padding: 0;
    }

    .van-cell {
      padding: 10px 0;
      border-bottom: 1px solid #dfe4eb;
    }

    .van-cell::after,
    .van-cell__right-icon {
      display: none;
    }
  }
}
// .fixed-btn {
//   .add-btn {
//     margin-bottom: 22px;
//   }
// }
</style>
