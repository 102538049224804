<template>
  <van-tabs
    v-model="active"
    class="tab-page"
    color="#055C9D"
    title-active-color="rgba(0, 0, 0, 0.9)"
    title-inactive-color="rgba(0, 0, 0, 0.4)"
    line-width="106"
  >
    <slot />
  </van-tabs>
</template>

<script>
import { getAction } from "@/api/manage";
import { mapGetters } from "vuex";

export default {
  name: "TabPage",
  data() {
    return {
      active: this.$route.query.active || "",
      url: {
        checkSampleFinish:
          "/jeecg-system/task/taskOrderItem/check_sample_finish",
      },
    };
  },
  computed: {
    ...mapGetters(["taskDetail"]),
  },
  methods: {
    // 判断是否全部完成点数抽样
    // async checkSampleFinish(name) {
    //   console.log(name)
    //   const { success, result } = await getAction(this.url.checkSampleFinish, {
    //     taskId: this.taskDetail.id,
    //   });
    //   if (success) {
    //     if(name=='task' && !result) {
    //       this.Toast.fail("请先完成点数抽样");
    //       this.active=this.$route.query.active
    //     } 
    //     else this.handleSwitchTab(name)
    //   }
    // },
    handleSwitchTab(name) {
      const routeName = this.$route.name;
      const query = this.$route.query;
      this.$router.replace({
        name: routeName,
        query: {
          ...query,
          active: name,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.tab-page {
  border-top: 1px solid #dfe4eb;
}
</style>
