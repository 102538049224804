<template>
  <div>
     <custom-popup  :show="showPopup"
        @on-close="$emit('on-close')"
        title="新增自定义检查点"> 11111</custom-popup>
  </div>
</template>

<script>
import CustomPopup from "@/components/Common/CustomPopup.vue";
export default {
    components:{
        CustomPopup
    }
}

</script>

<style>

</style>