<template>
  <van-tab v-bind="$attrs">
    <div class="tab-content" :style="detail.supplierComments == 'PENDING'?'height: calc(100vh - 106px - 66px)':'height: calc(100vh - 66px)'">
       <!-- :style="detail.supplierComments == 'PENDING'?'height: calc(100vh - 66px)':'height: calc(100vh - 106px - 66px)'" -->
      <card :title="detail.title">
        <span class="basic-item">申请数量: {{ detail.inspectQuantity }}</span>
        <span class="basic-item"
          >抽检水平: {{ SAMPLING_LEVEL_DICT[detail.samplingLevel] }}</span
        >
        <span class="basic-item">建议抽检: {{ detail.sampleQuantity }}</span>
      </card>
      <card title="检查项">
        <div class="info-item space-between">
          <div class="label">产品类目</div>
          <div class="value">
            {{
              detail.productBaseInfo &&
              detail.productBaseInfo.firstGoodsTypeName
            }}/{{
              detail.productBaseInfo &&
              detail.productBaseInfo.secondGoodsTypeName
            }}/{{
              detail.productBaseInfo &&
              detail.productBaseInfo.thirdGoodsTypeName
            }}
          </div>
        </div>
        <div class="info-item space-between">
          <div class="label">产品品牌</div>
          <div class="value">
            {{ detail.productBaseInfo && detail.productBaseInfo.brand }}
          </div>
        </div>
        <div class="info-item space-between">
          <div class="label">产品系列</div>
          <div class="value">
            {{ detail.productBaseInfo && detail.productBaseInfo.series }}
          </div>
        </div>
        <div class="info-item space-between">
          <div class="label">产品备注</div>
          <div class="value">
            {{ detail.productBaseInfo && detail.productBaseInfo.remark }}
          </div>
        </div>
        <div class="info-item space-between">
          <div class="label">验货数量</div>
          <div class="value">{{ detail.inspectQuantity }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">抽检水平</div>
          <div class="value">
            {{ SAMPLING_LEVEL_DICT[detail.samplingLevel] }}
          </div>
        </div>
        <div class="info-item space-between">
          <div class="label">抽检数量</div>
          <div class="value">{{ detail.sampleQuantity }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">AQL</div>
          <div class="value">
            CRI {{ detail.aqlCri }} MAJ {{ detail.aqlMaj }} MIN
            {{ detail.aqlMin }}
          </div>
        </div>
      </card>
    </div>
    <div
      class="fixed-btn BTN_box"
      v-if="detail.supplierComments == 'PENDING'"
    >
      <!-- <van-button type="primary" color="#055C9D" block @click="handleStartClick">开始验货</van-button> -->
      <van-button color="#055C9D" block @click="openPopup"
        >同意验货结论</van-button
      >
      <van-button color="#055C9D" @click="UnAgreeOpenPopup" block
        >不同意验货结论</van-button
      >
    </div>

    <!-- <custom-popup
      :show="showPopupAgree"
      @on-close="showPopupAgree=false"
      title="同意"
    >
     <div class="info-item space-between">
          <div class="label">申述理由</div>
          <div class="value">{{ detail.sampleQuantity }}</div>
        </div>
    </custom-popup> -->

    <!-- <custom-popup
      :show="showPopupUnAgree"
      @on-close="showPopupUnAgree = false"
      title="不同意"
    >
    </custom-popup> -->

    <!-- <gong-huo-check-info-tab-modal><gong-huo-check-info-tab-modal/> -->
    <!-- <van-cell is-link @click="openPopup">展示弹出层</van-cell> -->
    <van-popup v-model="showPopupAgree">
      <div style="width: 320px; height: 225px" class="tancen">
        <p>温馨提示</p>
        <div class="tancen_content">
          同意验货结论后，将被视为经验货员与供应商双方同意后的最终结论，不再接受申诉。
        </div>
        <div class="tancen_BTN_box">
          <van-button color="#055C9D" @click="UnAgreeOpenPopup" block
            >不同意验货结论</van-button
          >
        </div>
      </div>
    </van-popup>
    <van-dialog
      v-model="showPopupUnAgree"
      title="不同意验货结论的理由申诉"
      show-cancel-button
      @cancel="chanceBtn"
      @confirm="confirmBtn"
    >
      <van-form ref="Form">
        <van-field
          style="flex-direction: column"
          v-model="form.supplierAppealReason"
          name="申诉理由"
          label="申诉理由"
          placeholder="请输入"
          :rules="[{ required: true, message: '请填写申诉理由' }]"
          autosize
          rows="2"
          type="textarea"
          maxlength="50"
          show-word-limit
        />
        <p
          style="
            padding-left: 0.42667rem;
            color: #646566;
            text-align: left;
            font-size: 0.37333rem;
          "
        >
          证据照片
        </p>
        <van-uploader
          v-model="imgList"
          :after-read="handleReadImg"
          :preview-size="70"
          :upload-icon="require('@/assets/png/paizhao.png')"
          style="padding-left: 0.42667rem"
        />
        <!-- <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div> -->
      </van-form>
    </van-dialog>
  </van-tab>
</template>

<script>
import { axios } from "@/utils/request";
import { Dialog, Toast } from "vant";
import CustomPopup from "@/components/Common/CustomPopup.vue";
import Card from "@/components/Common/Card.vue";
import { getAction, putAction } from "@/api/manage";
import { SAMPLING_LEVEL_DICT } from "@/constants";
import GongHuoCheckInfoTabModal from "./module/GongHuoCheckInfoTabModal.vue";
export default {
  name: "CheckInfoTab",
  components: { Card, CustomPopup, Dialog },
  props: {
    id: {
      type: String,
      required: false,
    },
    detail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      imgList: [],
      form: {
        supplierAppealReason: "",
        supplierAppealImg: "",
      },
      showPopupUnAgree: false, //不同意是否弹出
      showPopupAgree: false, //同意是否弹出
      SAMPLING_LEVEL_DICT,
    };
  },
  methods: {
    openPopup() {
      let that = this;
      // this.showPopupAgree = !this.showPopupAgree;
      Dialog.confirm({
        title: "温馨提示",
        message:
          "同意验货结论后，将被视为经验货员与供应商双方同意后的最终结论，不再接受申诉。",
      })
        .then(() => {
          // on confirm
          putAction("/jeecg-system/task/taskOrderItem/edit", {
            id: that.detail.id,
            supplierComments: "AGREE",
          }).then((res) => {
            // this.$forceUpdate()
            location.reload();
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    UnAgreeOpenPopup() {
      this.showPopupUnAgree = !this.showPopupUnAgree;
    },
    handleStartClick() {
      this.$router.push({
        name: `taskCheck`,
        query: {
          ...this.$route.query,
          step: this.detail?.status === "COMPLETED" ? "3" : "1",
        },
      });
    },
    handleReadImg(info, name) {
      const data = new FormData();
      data.append("file", info.file);
      axios({
        url: "jeecg-system/platform/common/upload_file",
        method: "post",
        data,
        headers: {
          "Content-Type": "multipart/form-data", // 文件上传
        },
      })
        .then((res) => {
          if (res.success) {
            info.url = res.message;
            info.content = res.message;
          } else {
            // 上传失败后，删除该图片
            let index = this.form.imgList.findIndex(
              (item) => item.content === info.content
            );
            if (index > -1) {
              this.form.imgList.splice(index, 1);
            }
            this.Toast.fail(res.message || "上传图片失败!");
          }
        })
        .catch((error) => {
          // 上传失败后，删除该图片
          let index = this.form.imgList.findIndex(
            (item) => item.content === info.content
          );
          if (index > -1) {
            this.form.imgList.splice(index, 1);
          }
          this.Toast.fail("上传图片失败");
        });
    },
    chanceBtn() {
      this.showPopupUnAgree = false;
      this.form = {};
      this.imgList = [];
      this.$refs.Form.resetValidation();
    },
    confirmBtn() {
      // console.log(this.form,this.imgList);
      if (this.form.supplierAppealReason) {
        let dataList = JSON.parse(JSON.stringify(this.form));
        dataList.id = this.detail.id;
        dataList.supplierComments = "DISAGREE";
        if (this.imgList.length > 0) {
          this.imgList.map((item) => {
            dataList.supplierAppealImg += item.url + ",";
          });
          dataList.supplierAppealImg = dataList.supplierAppealImg.substr(
            0,
            dataList.supplierAppealImg.length - 1
          );
        }
        putAction("/jeecg-system/task/taskOrderItem/edit", dataList).then(
          (res) => {
            if (res.success) {
              Toast.success("提交成功!");
              this.chanceBtn();
              location.reload();
            } else {
              this.Toast.fail(res.message);
            }
          }
        );
      }
    },
  },
};
</script>

<style scoped lang="less">
.tancen {
  > p {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-family: PingFangSC-S0pxibold;
  }
  .tancen_content {
    width: 272px;
    height: 72px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    line-height: 24px;
    margin: 0 auto;
  }
}
.BTN_box {
  display: flex;
  > button:last-child {
    color: #055c9d !important;
    margin: 0 0.1rem;
    background-color: #fff !important;
  }
}
.tab-content {
  // height: calc(100vh - 106px - 66px);

  .basic-item {
    margin-right: 8px;
    color: rgba(#000, 0.4);
    font-size: 14px;
  }

  .info-item {
    line-height: 20px;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .label {
      width: 80px;
      font-size: 12px;
      color: rgba(#000, 0.4);
    }

    .value {
      width: calc(100% - 80px);
      font-size: 14px;
    }
  }
}
</style>
